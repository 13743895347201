$black: #000000;
$soil: #111111;
$white: #ffffff;
$light: #f1f1f1;
$ghost: #eeeeee;
$dark-grey: #666666;
$orange: #af452e;

@mixin text-shadow($shadows...) {
    text-shadow: $shadows
}    
body,html {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif, arial, tahoma;
    color: $dark-grey;
    background: $white;
	font-size: 16px;
    line-height: 1.6em;
}


.navbar {
    border: none;
}

.navbar-default .navbar-brand {
    color: $white;
}

.navbar-default .navbar-nav>li>a {
    color: $ghost;
    font-weight: 700;
}

.navbar-default .navbar-nav>li>a:hover {
    color: $white;
}


#nav.navbar, #nav.navbar-default{
    background: $black; 
}

@media only screen and (min-width: 480px) {

    #nav.navbar, #nav.navbar-default{
        background: $black url("../img/nav-bg.jpg") no-repeat; 
    }
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border: none;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: $white;
    background-color: $black;
}

section {
    margin: 70px 0;    
}

footer {
    background: $soil;
}

footer {
	text-align: center;
	padding: 20px 0 ;
	
}

footer a.totop:focus {
	outline: 0;
}

footer.copy, footer.copy a {
    color: $white;
}

#intro.video { 
	position: relative; 
	margin: 0 auto; 
	text-align: center;
	height: 0;
	padding-top: 20%;
	padding-bottom: 30%;
	position: relative;
	overflow: hidden;
	
}

#intro.video .video-container { height: 100%; width: 100%; position: absolute; z-index: 1; background-size: cover; }

#intro.video .overlay { 
	position: relative; 
	height: 100%; 
	z-index: 2; 
	padding: 25% 0;

}

#intro.video .video-wrapper {
	top: 50px;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.text-light {
	color: $light;
}

.text-dark-orange {
    color: $orange;
}

.text-black {
    color: $black;  
}

.text-grey {
    color: $dark-grey;    
}

.big-heading {
	font-size: 52px;
	font-weight: 700;
}

@media only screen and (max-width: 480px) {
    #intro.video .overlay { 
        padding: 0;
    }
    .big-heading {
        font-size: 32px;
    }

}

@media only screen and (max-width: 768px) and (min-width: 481px) {
    #intro.video .overlay { 
        padding: 10% 0;
    }       
}

h1.h-bold,h2.h-bold,h3.h-bold ,h4.h-bold ,h5.h-bold ,h6.h-bold  {
	font-weight: 700;
    text-transform: uppercase;
    color: $black;
}

.divider-header {
	display: block;
	width: 50px;
	margin: 0 auto;
	margin-bottom: 10px;
    border-bottom: 2px solid $black;
}

.parallax-window {
    min-height: 400px;
    background: transparent;
}

.parallax-window.page {
    min-height: 450px;
}

.parallax-window .overlay{ 
	position: relative; 
	padding: 10% 0;

}

.parallax-window .overlay a{
    color: $light;
}

.parallax-txt-shadow-grey {
    @include text-shadow(2px 2px 4px $dark-grey);
}

.parallax-txt-shadow-white {
    @include text-shadow(2px 2px 4px $white);
}

@media only screen and (max-width: 480px) {
    .parallax-window {
        min-height: 200px;
    }
    .parallax-window.page {
        min-height: 200px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
    .parallax-window .overlay { 
        padding: 20% 0;
    }       
}

.counter { background-color: $white; padding: 20px 0; border-radius: 5px;}
.count-title { font-size: 40px; font-weight: normal;  margin-top: 10px; margin-bottom: 0; text-align: center; }
.count-text { font-size: 13px; font-weight: normal;  margin-top: 10px; margin-bottom: 0; text-align: center; }
/*
.modal-body > .img-responsive {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
*/
.modal-header {
    background: $ghost;
    color: $black;
}


img.lazy {
    width: 228px; 
    height: 228px; 
    display: block;
}

#products a, #products a:hover {
    text-decoration: none;
}

img.brands{
   max-width: 200px;
   height: auto;
}
